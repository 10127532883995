import { Box } from "@material-ui/core";
import "./index.scss";
import Reveal, { Roll, Slide } from "react-awesome-reveal";
import { CryptocurrencyMarket, MarketOverview } from "react-tradingview-embed";

const PlatformPage = () => {
  // const mobileWidth = window.innerWidth;
  return (
    <>
      <div
        style={{
          marginTop: window.location.href.includes("platform") ? "3rem" : "0",
        }}
        className="platform-page-video-container"
      ></div>
      {/* <h1
        style={{
          display: !window.location.href.includes("platform")
            ? "none"
            : "block",
        }}
        className="platform-page-header"
      >
        Platform
      </h1> */}
      <Box className="platform-page-container">
        <Box
          style={{ display: "flex" }}
          className="platform-page-inner-container"
        >
          <Reveal className="platform-page-reveal-container" duration={2000}>
            <Box className="platform-page-textBox">
              <h3 className="platform-page-h3">
                Trade Leading Global Assets with <br></br>Our Advanced Trading
                Platform
              </h3>
              <Box className="platform-page-span">
                <span>
                  At Pier Point Global, we are committed to serving traders
                  worldwide by offering online CFDs (Contracts for Difference)
                  <br></br> and a diverse range of financial instruments. To
                  enhance both safety and ease of trading, our innovative{" "}
                  <br></br>platform provides access to multiple financial
                  markets—all in one place, from
                  <br></br>
                  commodities to cryptocurrencies.
                </span>
              </Box>
            </Box>
          </Reveal>
          <Box style={{ alignSelf: "center", marginTop: "5%" }}>
            <MarketOverview
              widgetProps={{
                theme: "dark",
                showChart: true,
                locale: "en",
                width:
                  window.innerWidth <= 667
                    ? 400
                    : window.innerWidth >= 1050 && window.innerWidth <= 1300
                    ? 500
                    : 600,
                height: window.innerWidth <= 667 ? 500 : 600,
              }}
            />
          </Box>
        </Box>
        <Box
          className="platform-page-crypto-chart-container"
          style={{ display: "flex", paddingLeft: "5%" }}
        >
          <CryptocurrencyMarket
            widgetProps={{
              width:
                window.innerWidth <= 667
                  ? 400
                  : window.innerWidth >= 1050 && window.innerWidth <= 1300
                  ? 800
                  : 900,
              theme: "dark",
              showChart: true,
              locale: "en",
            }}
          />
          <img
            src="trading-view.png"
            width={450}
            height={450}
            style={{ alignSelf: "center", marginLeft: "5%" }}
          ></img>
        </Box>
      </Box>
    </>
  );
};

export default PlatformPage;
