import { Box } from "@material-ui/core";
import "./index.scss";
import { Slide } from "react-awesome-reveal";
const FeaturesPage = () => {
  return (
    <>
      {/* <h1 className="features-page-header">Features</h1> */}

      <Box
        style={{
          marginTop: window.location.href.includes("features") ? "3rem" : "0",
        }}
        className="features-page-container"
      >
        <Box className="features-page-textBox">
          <h3 className="features-page-h3">What do we offer?</h3>
          <Box className="features-page-span">
            <span>
              At Pier Point Global, we recognize the significance of equipping
              our clients with top-tier tools and services to enhance their
              market success.
            </span>
          </Box>
        </Box>
        <Box className="features-types-container">
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-wallet.svg" width={"140px"} alt="a"></img>
              <h2 className="features-types-headers">Secure Wallet</h2>
              <span className="features-types-spans">
                A cutting-edge, secure storage solution utilizing advanced
                technology to safeguard your funds.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-advisor.svg" width={"140px"} alt="a"></img>

              <h2 className="features-types-headers">
                Personal Financial Analyst
              </h2>
              <span className="features-types-spans">
                Access to a dedicated financial expert who will provide insights
                and guidance <br></br>tailored to your trading needs.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-portfolio.svg" width={"140px"} alt="a"></img>

              <h2 className="features-types-headers">Portfolio Management</h2>

              <span className="features-types-spans">
                A comprehensive platform designed to align your investments
                <br></br> with your financial goals.
              </span>
            </Box>
          </Slide>
        </Box>
        <Box className="features-types-container">
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-financial-tools.svg" width={"140px"} alt="a"></img>

              <h2 className="features-types-headers">Advanced Trading Tools</h2>
              <span className="features-types-spans">
                State-of-the-art risk management resources aimed at reducing
                <br></br>
                risk and maximizing profit potential.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-support.svg" width={"140px"} alt="a"></img>

              <h2 className="features-types-headers">Customer Support</h2>
              <span className="features-types-spans">
                Live support available via chat, phone, and email, ensuring
                <br></br>
                assistance is just a call away, 24/5.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-shield.svg" width={"140px"} alt="a"></img>

              <h2 className="features-types-headers">Security</h2>

              <span className="features-types-spans">
                Your security is our priority. We continuously monitor and
                <br></br>
                implement measures to prevent unauthorized access to your
                <br></br>
                account.
              </span>
            </Box>
          </Slide>
        </Box>
      </Box>
    </>
  );
};

export default FeaturesPage;
